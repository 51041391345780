body {
  overflow-x: hidden; }

.Wechat_Middle_one {
  width: 100%;
  background: #f5f9ff;
  padding-bottom: 40px; }
  .Wechat_Middle_one .ContainerBox {
    width: 1200px;
    height: 754px;
    margin: 0 auto;
    text-align: center; }
    .Wechat_Middle_one .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      padding-top: 60px; }
      .Wechat_Middle_one .ContainerBox .Title span {
        color: #006AEB; }
    .Wechat_Middle_one .ContainerBox .SubTitle {
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .Wechat_Middle_one .ContainerBox .Content {
      width: 1200px;
      margin: 0px auto 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-top: 10px; }
      .Wechat_Middle_one .ContainerBox .Content li {
        width: 300px;
        float: left;
        justify-content: center;
        padding: 45px 50px; }
        .Wechat_Middle_one .ContainerBox .Content li .Image {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: #fff;
          margin: 0 auto;
          text-align: center;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
          padding-top: 36px;
          transition: all 0.5s ease-in-out;
          border: 1px solid #e6e6e6; }
        .Wechat_Middle_one .ContainerBox .Content li .Image:hover {
          cursor: pointer;
          transform: rotate3d(0, 1, 0, 35deg);
          box-shadow: 30px 0px 15px 0px rgba(0, 0, 0, 0.15); }
        .Wechat_Middle_one .ContainerBox .Content li .TextTitle {
          text-align: center;
          font-size: 20px;
          color: #333333;
          margin: 15px 0 8px 0; }
        .Wechat_Middle_one .ContainerBox .Content li .Text {
          text-align: left;
          font-size: 14px;
          color: #333; }
      .Wechat_Middle_one .ContainerBox .Content li:nth-child(4) {
        margin-right: 0; }
      .Wechat_Middle_one .ContainerBox .Content li:nth-child(8) {
        margin-right: 0; }

.Wechat_Middle_two {
  width: 100%;
  height: 716px;
  background: white; }
  .Wechat_Middle_two .ContainerBox {
    width: 1200px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto; }
    .Wechat_Middle_two .ContainerBox .Title {
      text-align: center;
      color: #333;
      font-size: 24px;
      padding-top: 60px;
      margin-bottom: 15px; }
      .Wechat_Middle_two .ContainerBox .Title span {
        color: #006AEB; }
    .Wechat_Middle_two .ContainerBox .SubTitle {
      text-align: center;
      color: #666;
      font-size: 16px;
      width: 520px;
      margin: 0 auto; }
    .Wechat_Middle_two .ContainerBox .Content {
      width: 1200px;
      height: 440px;
      margin: 60px auto 0 auto; }
      .Wechat_Middle_two .ContainerBox .Content li {
        width: 560px;
        height: 200px;
        float: left;
        margin-right: 80px;
        margin-bottom: 40px; }
        .Wechat_Middle_two .ContainerBox .Content li .Left {
          width: 160px;
          height: 200px;
          background: #EBF3FF;
          text-align: center;
          float: left;
          padding-top: 70px; }
          .Wechat_Middle_two .ContainerBox .Content li .Left img {
            transition: all 0.8s ease; }
        .Wechat_Middle_two .ContainerBox .Content li .Left:hover {
          cursor: pointer; }
          .Wechat_Middle_two .ContainerBox .Content li .Left:hover img {
            transform: scale(1.3); }
        .Wechat_Middle_two .ContainerBox .Content li .Right {
          width: 400px;
          height: 200px;
          padding-left: 20px;
          float: left; }
          .Wechat_Middle_two .ContainerBox .Content li .Right .TextTitle {
            font-size: 20px;
            color: #006AEB;
            margin-bottom: 20px; }
          .Wechat_Middle_two .ContainerBox .Content li .Right .TextCont {
            line-height: 24px;
            font-size: 14px;
            color: #666666; }
      .Wechat_Middle_two .ContainerBox .Content li:nth-child(2) {
        margin-right: 0; }
      .Wechat_Middle_two .ContainerBox .Content li:nth-child(4) {
        margin-right: 0; }

.Wechat_Middle_three {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0; }
  .Wechat_Middle_three .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .Wechat_Middle_three .Title span {
      color: #006AEB; }
  .Wechat_Middle_three .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .Wechat_Middle_three .Content {
    width: 100%;
    margin: 56px auto 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .Wechat_Middle_three .Content a {
      width: 292px;
      height: 292px;
      position: relative;
      margin: 0 8px 30px 0;
      overflow: hidden; }
      .Wechat_Middle_three .Content a .Img {
        width: 292px;
        height: 292px;
        transition: all 1s;
        transform-origin: left bottom; }
      .Wechat_Middle_three .Content a .Mask {
        position: absolute;
        text-align: center;
        width: 292px;
        height: 292px;
        left: 0;
        top: 0;
        display: none;
        opacity: 0.6; }
      .Wechat_Middle_three .Content a .TextBox {
        width: 230px;
        text-align: center;
        position: absolute;
        color: white;
        z-index: 1;
        font-size: 20px;
        display: none; }
      .Wechat_Middle_three .Content a .Line {
        width: 260px;
        height: 240px;
        position: absolute;
        border: 2px solid #FFF;
        z-index: 1;
        display: none;
        border-radius: 4px; }
        .Wechat_Middle_three .Content a .Line img {
          width: 50px;
          height: 36px;
          position: absolute;
          right: 20px;
          bottom: -18px; }
    .Wechat_Middle_three .Content a:hover {
      cursor: pointer; }
      .Wechat_Middle_three .Content a:hover .Img {
        transform: scale(1.1); }
      .Wechat_Middle_three .Content a:hover .Mask {
        display: block; }
      .Wechat_Middle_three .Content a:hover .TextBox {
        display: block; }
      .Wechat_Middle_three .Content a:hover .Line {
        display: block; }
  .Wechat_Middle_three .Button {
    display: block;
    width: 150px;
    height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center; }
  .Wechat_Middle_three .Button:hover {
    cursor: pointer; }

.Wechat_Middle_four {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0 40px 0; }
  .Wechat_Middle_four .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .Wechat_Middle_four .Title span {
      color: #006AEB; }
  .Wechat_Middle_four .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .Wechat_Middle_four .Buttom {
    width: 100%;
    height: 360px;
    margin-top: 60px;
    margin-bottom: 40px; }
    .Wechat_Middle_four .Buttom li {
      width: 360px;
      height: 360px;
      float: left;
      overflow: hidden;
      background: white;
      box-shadow: 0px 5px 15px 0px rgba(49, 49, 49, 0.15);
      margin-right: 30px;
      position: relative; }
      .Wechat_Middle_four .Buttom li .ImgBox {
        width: 360px;
        height: 240px; }
        .Wechat_Middle_four .Buttom li .ImgBox .Img {
          width: 360px;
          height: 240px; }
      .Wechat_Middle_four .Buttom li .P_Con {
        margin: 12px 20px 0 10px;
        text-align: left; }
      .Wechat_Middle_four .Buttom li .P_Head {
        padding-top: 10px;
        font-size: 16px; }
      .Wechat_Middle_four .Buttom li .P_Text {
        font-size: 14px;
        color: #999999;
        padding-bottom: 10px; }
      .Wechat_Middle_four .Buttom li .Mask_DeatilsBtm {
        position: absolute;
        top: 0;
        left: 0;
        width: 360px;
        height: 240px;
        background: #006aeb;
        opacity: 0.8;
        display: none; }
      .Wechat_Middle_four .Buttom li .Triangle {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 18px solid white;
        position: absolute;
        bottom: 110px;
        right: 60px;
        display: none; }
      .Wechat_Middle_four .Buttom li .MaskText {
        width: 360px;
        height: 240px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        padding: 88px 0;
        display: none; }
        .Wechat_Middle_four .Buttom li .MaskText p:nth-child(1) {
          font-size: 14px;
          color: white; }
        .Wechat_Middle_four .Buttom li .MaskText p:nth-child(2) {
          font-size: 24px;
          color: white; }
    .Wechat_Middle_four .Buttom li:nth-child(3) {
      margin-right: 0; }
    .Wechat_Middle_four .Buttom li:hover {
      cursor: pointer; }
      .Wechat_Middle_four .Buttom li:hover .Triangle {
        display: block; }
      .Wechat_Middle_four .Buttom li:hover .MaskText, .Wechat_Middle_four .Buttom li:hover .Mask_DeatilsBtm {
        display: block; }
      .Wechat_Middle_four .Buttom li:hover .P_Head {
        color: #006AEB; }
  .Wechat_Middle_four .button {
    display: block;
    width: 150px;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    color: #fff;
    font-size: 16px;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 53px;
    text-align: center; }
  .Wechat_Middle_four button:hover {
    cursor: pointer; }

.Wechat_Process {
  width: 100%;
  height: 680px;
  background: url("../../assets/common/images/kaifaliucheng-bg.png") no-repeat; }
  .Wechat_Process .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .Wechat_Process .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      padding-top: 60px;
      text-align: center;
      color: #fff; }
    .Wechat_Process .ContainerBox .SubTitle {
      color: #fff;
      font-size: 16px;
      width: 520px;
      margin: 0 auto;
      text-align: center; }
    .Wechat_Process .ContainerBox ul {
      width: 1200px;
      overflow: hidden;
      margin: 0 auto; }
      .Wechat_Process .ContainerBox ul li {
        width: 171px;
        overflow: hidden;
        text-align: center;
        float: left; }
        .Wechat_Process .ContainerBox ul li p {
          margin-top: 30px;
          color: #fff;
          font-size: 20px; }
    .Wechat_Process .ContainerBox .Content_One {
      margin-top: 90px; }
    .Wechat_Process .ContainerBox .Content_Two {
      margin: 50px 0; }
      .Wechat_Process .ContainerBox .Content_Two li {
        float: right; }
