.Mobile_Wechat {
  width: 100%; }
  .Mobile_Wechat .Mobile_WechatBox {
    padding: 0.56rem 0.66rem 0 0.66rem;
    text-align: center; }
    .Mobile_Wechat .Mobile_WechatBox .Mobile_Title {
      font-size: 0.36rem;
      color: #333; }
      .Mobile_Wechat .Mobile_WechatBox .Mobile_Title span {
        color: #006AEB; }
    .Mobile_Wechat .Mobile_WechatBox .Mobile_SubTitle {
      font-size: 0.2rem;
      color: #666;
      margin: 0.2rem 0 0.4rem 0; }
    .Mobile_Wechat .Mobile_WechatBox .Mobile_ShowBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .Mobile_Wechat .Mobile_WechatBox .Mobile_ShowBox .Mobile_Show {
        flex-direction: column;
        margin-bottom: 0.4rem; }
        .Mobile_Wechat .Mobile_WechatBox .Mobile_ShowBox .Mobile_Show .Round {
          width: 1.6rem;
          height: 1.6rem;
          background: #fff;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center; }
        .Mobile_Wechat .Mobile_WechatBox .Mobile_ShowBox .Mobile_Show .Show_Head {
          font-size: 0.3rem;
          margin: 0.3rem 0 0 0;
          text-align: left;
          color: #333; }
        .Mobile_Wechat .Mobile_WechatBox .Mobile_ShowBox .Mobile_Show .TextShow {
          text-align: left;
          width: 2.4rem;
          color: #666;
          font-size: 0.24rem; }
  .Mobile_Wechat .Mobile_WechatPro {
    width: 100%;
    height: 6.8rem;
    padding-top: 0.5rem;
    background: url("../../assets/common/images/weixinkaifaliucheng@3x.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #fff; }
    .Mobile_Wechat .Mobile_WechatPro .Mobile_Title {
      font-size: 0.36rem; }
    .Mobile_Wechat .Mobile_WechatPro .Mobile_SubTitle {
      font-size: 0.2rem; }
  .Mobile_Wechat .Mobile_Case {
    width: 100%;
    text-align: center;
    padding: 0.4rem 0.62rem 0.4rem 0.65rem; }
    .Mobile_Wechat .Mobile_Case .Mobile_Title {
      font-size: 0.36rem;
      color: #333;
      margin-bottom: 0.1rem; }
    .Mobile_Wechat .Mobile_Case table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 0.3rem; }
      .Mobile_Wechat .Mobile_Case table td {
        width: 2.92rem;
        height: 2.92rem; }
        .Mobile_Wechat .Mobile_Case table td img {
          width: 2.92rem;
          height: 2.92rem; }
    .Mobile_Wechat .Mobile_Case button {
      width: 1.8rem;
      height: 0.6rem;
      background: linear-gradient(90deg, #006aeb, #009eff);
      margin: 0 auto;
      border-radius: 0.06rem; }
